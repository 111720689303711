import React from 'react';
import { PageProps } from 'gatsby';
import { WithTranslation, withTranslation, Trans } from 'react-i18next';
import Layout, { LayoutOptions, HeroSectionColors } from '../../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../../localization/translations';
import CommonData from '../../../data/common/common';
import ExternalLink from '../../../components/ExternalLink/ExternalLink';
import './style.scss';

type Props = PageProps & WithTranslation;

const NextJobProgram: React.FC<Props> = (props) => {
  const { t } = props;

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('nextJobProgram.seo.title'),
    },
    page: {
      title: t('nextJobProgram.heading'),
    },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.SKY_BLUE,
        heroImage: {
          fileName: 'hero-image-16.jpg',
          alt: 'Image',
        },
        shouldBeDigiBlock: true,
        blockText: t('nextJobProgram.tagline'),
      },
    },
  };

  const generateList = (list: string[]) => {
    return (
      <ul>
        {list.map((str, i) => {
          return <li key={`${str}-${i}`}>{str}</li>;
        })}
      </ul>
    );
  };

  return (
    <Layout options={layoutOptions} className="NextJobProgram">
      <div className="grid-container">
        <div className="row row-wrap NextJobProgram__first-row">
          <section className="column column-60">
            <div className="NextJobProgram__generic-gap">
              <h2>{t('nextJobProgram.losingYourJobBlock.heading')}</h2>
              <p>
                <Trans i18nKey="nextJobProgram.losingYourJobBlock.description" t={t} />
              </p>
            </div>
            <div className="NextJobProgram__generic-gap">
              <h2>{t('nextJobProgram.ourNextJobProgram.heading')}</h2>
              <p>
                <Trans i18nKey="nextJobProgram.ourNextJobProgram.description" t={t} />
              </p>
            </div>

            <div className="NextJobProgram__generic-gap">
              <h2>{t('nextJobProgram.whatDoesProgramOffer.heading')}</h2>
              <p>
                <Trans i18nKey="nextJobProgram.whatDoesProgramOffer.description" t={t} />
              </p>
              <Trans i18nKey="nextJobProgram.whatDoesProgramOffer.listOfAccess.heading" t={t} />
              <p />
              {generateList(
                t('nextJobProgram.whatDoesProgramOffer.listOfAccess.list', { returnObjects: true })
              )}
            </div>
            <p>
              <Trans i18nKey="nextJobProgram.subjectToApproval" t={t} />
            </p>
          </section>
          <aside className="column column-33">
            <h2>{t('nextJobProgram.weAreCallAway.heading')}</h2>
            <p>
              <Trans
                i18nKey="nextJobProgram.weAreCallAway.description"
                t={t}
                values={{
                  tollFreeNumber: CommonData.tollFreeCustomerServiceNumber.mortgageServicing,
                }}
              >
                <ExternalLink
                  href={`tel:${CommonData.tollFreeCustomerServiceNumber.mortgageServicing}`}
                  aria-label={`${t(`${LocaleNameSpaceKeys.COMMON}:tollFree`)} ${
                    CommonData.tollFreeCustomerServiceNumber.mortgageServicing
                  }`}
                >
                  {CommonData.tollFreeCustomerServiceNumber.mortgageServicing}
                </ExternalLink>
              </Trans>
            </p>
          </aside>
        </div>
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.CUSTOMER_CENTRE)(NextJobProgram);
